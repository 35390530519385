
import React, { useState,useRef,useEffect,forwardRef,useImperativeHandle,useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './Vip.scss';
import { InputNumber,Table,Modal,Input,Message,Avatar,Spin,Checkbox } from "@arco-design/web-react";

import "@arco-design/web-react/dist/css/arco.css";
import WxpayIcon from '../../../assets/wx.png'
import {
  useRecoilValue
} from 'recoil';
import { userInfoState} from '../store'
import QRCode  from 'qrcode.react';
import {vipList as getVipList,getOrder,coupon as postCoupon,payState} from '../../../api/all'

import useIntervalAsync from '../../hook/useIntervalAsync'

const VipDialog = forwardRef((props, ref) => {
  useImperativeHandle(ref, (props) => ({
    setVisible
  }));

  const [qrcodeScene,setQrcodeScene] = useState(null)
  const [vipMode,setVipMode] = useState(1)
  const [buyCount,setBuyCount] = useState(1)

  const navigate = useNavigate();
  const [vipList, setVipList] = useState([])
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderInfo, setOrderInfo] = useState(null);
  const [selectVipType,setSelectVipType] = useState(6)

  const [visibleExchange, setVisibleExchange] = useState(false);
  const [visibleTips, setVisibleTips] = useState(false);
  const [tipsType, setTipsType] = useState(1);
  const inputRef = useRef(null)

  const userInfo = useRecoilValue(userInfoState)

  useEffect(() => {
    if(visible){
      getUserInfo()
    }
  }, [visible])

  useEffect(() => {
    if(vipList.length){
      toBuy(vipList[selectVipType-6])
    }
  }, [selectVipType, vipList, buyCount])

  const updateState = useCallback(async () => {
    if(orderInfo && visible){
      const response = await payState({
        order_id:orderInfo?.order_id,
        count:100
      });
      if(response.code === 0){
        if(response.result[0].status === 2){
          update(0)
          setVisible(false)
          setOrderInfo(null)
          navigate('/success')
        }
      }
    }
  }, [orderInfo]);

  const update  =  useIntervalAsync(updateState, 3000);

  const getUserInfo = ()=>{
    getVipList().then(res => {
      let vip_list = res.result
      setVipList(vip_list)
    },error => {
      console.log("get request failed:",error);
    });
  }

  const toBuy = ({id,coupon=''})=>{
    let count = buyCount
    // setVisible(true)
    setLoading(true)
    getOrder({id,coupon,count}).then(res => {
      let data = res.result
      setOrderInfo(data)
      setLoading(false)
      update(1)
    },error => {
      setLoading(false)
      console.log("get request failed:",error);
    });
  }

  const onExchange = (code)=>{
    postCoupon({code}).then((res)=>{
      if(res.code === 0){
        let a = [...vipList]
        a.forEach((item) => {
          item.coupon = code
        })
        setVipList(a)
        setTipsType(1)
        setVisibleTips(true)
      }else{
        Message.error(res.message)
        setTipsType(0)
        setVisibleTips(true)
      }
    })
  }

  const vipListHtml = (vipList,type=1)=>{
    return <>
    <div className={["v-card ca-m", vipList[0]?.id === selectVipType?"v-card-check":""].join(' ')} onClick={()=>{setSelectVipType(vipList[0]?.id)}}>
      <div className='card-name'>
        {type===1?vipList[0]?.name:<>
          <span style={{fontSize:'12px'}}>会员模型</span>{vipList[0]?.count+'次'}
        </>}
      </div>
      <div className='price-tips'>
        <div className='v-price-box'><span>￥</span>{vipList[0]?.price}<span>/月</span></div>
        <div className='v-tips'>
          <span className={type===1?'':'mini-txt'}>会员模型</span>{vipList[0]?.count}次问<br/>答次数
        </div>
      </div>
    </div>

    {
      vipList[1]?<div className={["v-card ca-y", vipList[1]?.id === selectVipType?"v-card-check":""].join(' ')} onClick={()=>{setSelectVipType(vipList[1]?.id)}}>
        <div className='card-name'>
          {type===1?vipList[1]?.name:<>
            <span style={{fontSize:'12px'}}>会员模型</span>{vipList[1]?.count+'次'}
          </>}
        </div>
        <div className='price-tips'>
          <div className='v-price-box'><span>￥</span>{vipList[1]?.price}<span>/年</span></div>
          <div className='v-tips'>
            <span>会员模型</span>{vipList[1]?.count}次问<br/>答次数
          </div>
        </div>
      </div>:''
    }
    </>
  }

  return (
    <>
    <Modal
      className="vip_model"
      title={null}
      visible={visible}
      autoFocus={false}
      style={{
        width:'840px',
        height: '500px',
        background: '#FFFFFF',
        boxShadow: '0px 0px 11px 0px rgba(191,191,191,0.37)',
        borderRadius: '20px'
      }}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <div className='vip-content'>
        <div className='v-left'>
          <div className='welcom'>
            欢迎购买chatalls！
          </div>
          <div className='border-w'></div>
          <div className='gold-vip'><p>黄金会员</p></div>
          <div className='equity'>
            <div className='eq-item'>
              <span className='mark'></span>免费模型无限使用 
            </div>
            <div className='eq-item'>
              <span className='mark'></span>所有会员模型的对话权力
            </div>
            <div className='eq-item'>
              <span className='mark'></span>多模型PK的权利
            </div>
            <div className='eq-item'>
              <span className='mark'></span>加油包无限充值
            </div>
          </div>
          <div className='starbg'></div>
        </div>
        <div className='v-right'>
          <div className='v-tabs'>
            {
              !userInfo?.is_vip?<>
                <div className={vipMode===2?'v-tab vip v-check':'v-tab vip'} onClick={()=>{setVipMode(1);setBuyCount(1);setSelectVipType(6)}}>
                  会员
                </div>
                <div className={vipMode===1?'v-tab oil o-check':'v-tab vip'} onClick={()=>{setVipMode(2);setSelectVipType(8);setBuyCount(1)}}>
                  加油包
                </div>
              </>:<></>
            }
            
          </div>
          <div className='v-cont'>
            <div className='vc-left'>
              <div className='v-cards'>
                {/* {
                 vipMode===1? vipListHtml([vipList[0],vipList[1]],1):vipListHtml([vipList[2],{...vipList[2],...{
                  count:vipList[2].count*10,
                  price:vipList[2].price*10
                 }}],2)
                } */}
                {
                 vipMode===1? vipListHtml([vipList[0],vipList[1]],1):vipListHtml([vipList[2]],2)
                }
              </div>
              {
                vipMode===1?<div className='v-buyt'>
                <div className='v-title'>购买时长</div>
                <div className='months'>
                  <div className = {buyCount===1?'month one-m check-month':'month one-m'} onClick={()=>{
                    setBuyCount(1)
                  }}>
                    1{selectVipType===7?"年":'个月'}
                  </div>
                  <div className = {buyCount===2?'month two-m check-month':'month two-m'} onClick={()=>{
                    setBuyCount(2)
                  }}>
                    2{selectVipType===7?"年":'个月'}
                    {/* <div className='sale'>8折</div> */}
                  </div>
                </div>
              </div>:<div className='v-buyt'>
                <div className='v-title'>购买个数</div>
                <div className='number-input'>
                  <InputNumber value={buyCount} style={{ width: 150}} onChange={(val)=>{setBuyCount(val)}}/>
                </div>
              </div>
              }
              <div className='v-coupon'>
                <div className='v-title'>优惠券</div>
                <div className='coup-input'>
                  <Input className='input' ref={inputRef} allowClear placeholder='请输入优惠券码' />
                  <div className='check-btn' onClick={() => {
                    onExchange(inputRef.current.dom.value,selectVipType-6)
                  }}>
                    验券
                  </div>
                </div>
              </div>
            </div>
            <div className='vc-right'>
              <div className='v-title'>购买后方案</div>
              <div className='v-detail'>
                <div className='v-de-item'>
                  <div>购买会员类型</div><div>黄金会员</div>
                </div>
                <div className='v-de-item'>
                  <div>购买{selectVipType>7?'个数':'时长'}</div><div>{buyCount}{selectVipType===6?'个月':selectVipType===7?'年':'个'}</div>
                </div>
                <div className='v-de-item'>
                  <div>优惠券</div><div>{orderInfo?.coupon_id ? '有':'无'}</div>
                </div>
                <div className='v-de-item'>
                  <div>到期时间</div><div>{orderInfo?.end_time}</div>
                </div>
              </div>
              <div className='wx-pay-code'>
                <div className='qrcode-box'>
                  {(loading || !orderInfo)?<Spin loading={loading}></Spin> : <div>
                  <QRCode
                      value={orderInfo.url.code_url} //value参数为字符串类型
                      size={80} //二维码的宽高尺寸
                      fgColor="#000000"  //二维码的颜色
                  />
                  </div>}
                </div>
                <div className='pay-name'>
                  <img src={WxpayIcon} alt=''/><span>微信支付</span>
                </div>
                <div className='need-pay'>
                  需支付：<span className='rel-price'>{ loading?'...' :  (orderInfo?.price)}</span><span className='unit-yuan'>元</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    </>
  );
})

export default VipDialog;
